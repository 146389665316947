export type PointOfInterestCategory = "sports" | "services" | "activities" | "shops_banks" | "cafes_restaurants"

export type PointOfInterest = {
  poi_index: number
  category: PointOfInterestCategory
  name: string
  address: string
  time: number
  distance: number
}

export type CategoryMetadata = {
  title: string
  color: `#${string}`
}

export const POI_CATEGORY_METADATA: { [K in PointOfInterestCategory]: CategoryMetadata } = {
  activities: { title: "PARC & ACTIVITÉS", color: "#61B994" },
  cafes_restaurants: { title: "CAFÉS & BISTROTS & BARS & RESTAURANTS", color: "#FF3B30" },
  services: { title: "SERVICES", color: "#FF8137" },
  shops_banks: { title: "ÉPICERIES & BANQUES", color: "#82CEE3" },
  sports: { title: "SPORTS", color: "#FFB800" }
}

export const ALL_POINTS_OF_INTEREST: PointOfInterest[] = [
  {
    poi_index: 1,
    category: "sports",
    name: "Golf municipal de Montréal",
    address: "4235 Rue Viau, Montréal, QC H1T 3V2",
    time: 21,
    distance: 1.6
  },
  {
    poi_index: 2,
    category: "sports",
    name: "Stade Saputo",
    address: "4750 Rue Sherbrooke E, Montréal, QC H1V 3S8",
    time: 15,
    distance: 1.2
  },
  {
    poi_index: 3,
    category: "sports",
    name: "Aréna Maurice Richard",
    address: "2800 Rue Viau, Montréal, QC H1V 3J3",
    time: 12,
    distance: 1
  },
  {
    poi_index: 4,
    category: "sports",
    name: "Horizon Roc centre d’escalade",
    address: "2350 Rue Dickson, Montréal, QC H1N 3T1",
    time: 12,
    distance: 1
  },
  {
    poi_index: 5,
    category: "sports",
    name: "Aikido la forge",
    address: "3000 Rue Viau, Montréal, QC H1V 3J3",
    time: 12,
    distance: 0.9
  },
  {
    poi_index: 6,
    category: "sports",
    name: "Ski de fond parc Maisonneuve",
    address: "4601 Rue Sherbrooke E, Montréal, QC H1X 2B1",
    time: 20,
    distance: 1.5
  },
  {
    poi_index: 1,
    category: "services",
    name: "Polyclinique Maisonneuve Rosemont",
    address: "5345 Bd de l'Assomption, Montréal, QC H1T 4B3",
    time: 13,
    distance: 1
  },
  { poi_index: 2, category: "services", name: "Métro Viau", address: "", time: 15, distance: 1.2 },
  { poi_index: 3, category: "services", name: "Métro Assomption", address: "", time: 1, distance: 0.02 },
  {
    poi_index: 4,
    category: "services",
    name: "École des métiers de l’Aérospatiale de Montréal",
    address: "5300 Rue Chauveau, Montréal, QC H1N 3V7",
    time: 8,
    distance: 0.6
  },
  {
    poi_index: 5,
    category: "services",
    name: "École Marguerite-de-Lajammerais",
    address: "5555 Rue Sherbrooke E, Montréal, QC H1N 1A2",
    time: 8,
    distance: 0.6
  },
  {
    poi_index: 6,
    category: "services",
    name: "École Édouard Montpetit",
    address: "6200 Av. Pierre-De Coubertin, Montréal, QC H1N 1S4",
    time: 20,
    distance: 1.6
  },
  {
    poi_index: 1,
    category: "activities",
    name: "Stade Olympique",
    address: "4545 Av. Pierre-De Coubertin, Montréal, QC H1V 0B2",
    time: 17,
    distance: 1.3
  },
  {
    poi_index: 2,
    category: "activities",
    name: "Planetarium",
    address: "4801 Av. Pierre-De Coubertin, Montréal, QC H1V 3V4",
    time: 15,
    distance: 1.1
  },
  {
    poi_index: 3,
    category: "activities",
    name: "Parc Maisonneuve",
    address: "4601 Rue Sherbrooke E, Montréal, QC H1X 2B1",
    time: 20,
    distance: 1.5
  },
  {
    poi_index: 4,
    category: "activities",
    name: "Cinéma Star Cité",
    address: "4825 Av. Pierre-De Coubertin, Montréal, QC H1V 3V4",
    time: 15,
    distance: 1.2
  },
  {
    poi_index: 5,
    category: "activities",
    name: "Biodôme de Montréal",
    address: "4777 Av. Pierre-De Coubertin, Montréal, QC H1V 1B3",
    time: 16,
    distance: 1.3
  },
  {
    poi_index: 1,
    category: "shops_banks",
    name: "Marché Bonichoix",
    address: "5199 Rue Sherbrooke E #3350, Montréal, QC H1T 3X2",
    time: 10,
    distance: 0.65
  },
  {
    poi_index: 2,
    category: "shops_banks",
    name: "Desjardins",
    address: "5674 Rue Sherbrooke E, Montréal, QC H1N 1A1",
    time: 10,
    distance: 0.75
  },
  {
    poi_index: 3,
    category: "shops_banks",
    name: "Banque RBC",
    address: "5100 Rue Sherbrooke E, Montréal, QC H1V 3R9",
    time: 13,
    distance: 0.95
  },
  {
    poi_index: 1,
    category: "cafes_restaurants",
    name: "Madisons Restaurant & Bar",
    address: "5222 Rue Sherbrooke E, Montréal, QC H1V 1A1",
    time: 9,
    distance: 0.65
  },
  {
    poi_index: 2,
    category: "cafes_restaurants",
    name: "Cora déjeuners",
    address: "5670 Rue Sherbrooke E, Montréal, QC H1N 1A1",
    time: 9,
    distance: 0.65
  },
  {
    poi_index: 3,
    category: "cafes_restaurants",
    name: "Casa Grecques",
    address: "5787 Rue Sherbrooke E, Montréal, QC H1N 3R5",
    time: 11,
    distance: 0.8
  },
  {
    poi_index: 4,
    category: "cafes_restaurants",
    name: "Benni Hanna",
    address: "5666 Rue Sherbrooke E, Montréal, QC H1N 1A1",
    time: 8,
    distance: 0.65
  }
]
