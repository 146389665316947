import { useContext } from "react"
import { ALL_LEVELS, EXTERIOR_ONLY, INTERIOR_ONLY, ShowroomLevelContext } from "../../contexts/ShowroomLevelContext"
import { ShowroomWindowContext, ShowroomWindows } from "../../contexts/ShowroomWindowContext"
import { AddWindowParams } from "../../WindowManager/WindowManagerContext"
import { DockItem } from "../Dock"
import { IconType } from "../Icon"

const SIZE_CLASS_A = { width: 500, height: 600 }
const SIZE_CLASS_A_1 = { width: 500, height: 700 }
const SIZE_CLASS_B = { width: 330 }
const SIZE_CLASS_C = { width: 350, height: 500 }

const NEW_WINDOW: {
  [K: string]: AddWindowParams<ShowroomWindows>
} = {
  around: { family: ShowroomWindows.Around, title: "Environnant", sizing: SIZE_CLASS_A_1 },
  gallery: { family: ShowroomWindows.Gallery, title: "Gallerie", sizing: SIZE_CLASS_A },
  favorites: { family: ShowroomWindows.Favorites, title: "Favoris", sizing: SIZE_CLASS_A },
  amenities: { family: ShowroomWindows.Amenities, title: "Commodités", sizing: SIZE_CLASS_B },
  template_units: { family: ShowroomWindows.TemplateUnits, title: "Unités Témoins", sizing: SIZE_CLASS_C },
  filters: { family: ShowroomWindows.MasterFilters, title: "Filtres" },
  interior_customization: { family: ShowroomWindows.InteriorCustomization, title: "Personnalisation", sizing: SIZE_CLASS_B }
}

export const useDockItems = (): DockItem[] => {
  const levelCtx = useContext(ShowroomLevelContext)
  const windowCtx = useContext(ShowroomWindowContext)

  const items: DockItem[] = [
    {
      title: "Accueil",
      icon: IconType.vivenda,
      levelKinds: ALL_LEVELS,
      onClick: () => {
        const currentLevel = levelCtx.selectedLevel
        if (!currentLevel) return
        if (currentLevel.kind === "interior") {
          levelCtx.changeLevel("exterior_0")
          windowCtx.closeAll()
        }
      }
    },
    {
      title: "Environnant",
      icon: IconType.around,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.around)
    },
    {
      title: "Gallerie",
      icon: IconType.gallery,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.gallery)
    },
    {
      title: "Favoris",
      icon: IconType.heart,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.favorites)
    },
    {
      title: "Commodités",
      icon: IconType.people,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.amenities)
    },
    {
      title: "Unité témoin",
      icon: IconType.unit_interior,
      levelKinds: ALL_LEVELS,
      onClick: () => {
        const currentLevel = levelCtx.selectedLevel
        if (currentLevel?.kind === "interior") {
          return windowCtx.addWindow(NEW_WINDOW.template_units)
        }

        levelCtx.changeLevel("interior_0")
        windowCtx.closeAll()
        windowCtx.addWindow(NEW_WINDOW.template_units)
      }
    },
    {
      title: "Personnalisation",
      icon: IconType.gear,
      levelKinds: INTERIOR_ONLY,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.interior_customization)
    },
    {
      title: "Filtres",
      icon: IconType.filters,
      levelKinds: EXTERIOR_ONLY,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.filters)
    },
    { title: "Email", icon: IconType.mail, levelKinds: ALL_LEVELS },
    { title: "Utilisateur", icon: IconType.user_settings, levelKinds: ALL_LEVELS }
  ]

  const selectedLevel = levelCtx.selectedLevel
  if (!selectedLevel) return []
  return items.filter((item) => item.levelKinds.has(selectedLevel.kind))
}
