import EventEmitter from "events"
import { useCallback, useRef } from "react"
import { UseWebRTCProps } from "../webrtc"
import { UEReceiveMessageCodes } from "./messageCodes"

export type UseUEEventsProps = unknown

export type UseUEEvents = {
  onDataChannelMessage: UseWebRTCProps["onDataChannelMessage"]
  emitter: EventEmitter
}

export const useUEEvents = (): UseUEEvents => {
  const emitterRef = useRef(new EventEmitter())

  const onDataChannelMessage = useCallback<UseUEEvents["onDataChannelMessage"]>((ev) => {
    const { data } = ev
    const view = new Uint8Array(data)
    const code = view[0]
    // console.log("received code from ue:", { code, view })

    switch (code) {
      case UEReceiveMessageCodes["QualityControlOwnership"]:
        return emitQualityControlOwnership(data)
      case UEReceiveMessageCodes["Response"]:
        return emitResponse(data)
      case UEReceiveMessageCodes["InitialSettings"]:
        return emitInitialSettings(data)
    }
  }, [])

  const emitQualityControlOwnership = (data: ArrayBuffer) => {
    const view = new Uint8Array(data)
    emitterRef.current.emit("QualityControlOwnership", { ownership: view[1] !== 0 })
  }

  const emitResponse = (data: ArrayBuffer) => {
    const text = new TextDecoder("utf-16").decode(data.slice(1))
    const response = JSON.parse(text)
    emitterRef.current.emit("Response", { response })
  }

  const emitInitialSettings = (data: ArrayBuffer) => {
    const text = new TextDecoder("utf-16").decode(data.slice(1))
    const settings = JSON.parse(text)
    emitterRef.current.emit("InitialSettings", { settings })
  }

  return {
    onDataChannelMessage,
    emitter: emitterRef.current
  }
}
