import React, { PropsWithChildren, RefObject, useEffect, useRef } from "react"
import { useSignalling } from "../pixelStreaming/signalling"
import { UECommands, useUECommands } from "../pixelStreaming/ue4/commands"
import { useUEEvents } from "../pixelStreaming/ue4/events"
import { useUEMouseEvents } from "../pixelStreaming/ue4/mouse"
import { useUE4Video } from "../pixelStreaming/ue4/video"
import { useWebRTC } from "../pixelStreaming/webrtc"

export interface IPixelStreamingContext {
  videoRef: RefObject<HTMLVideoElement>
  playerRef: RefObject<HTMLDivElement>
  ueCommands: UECommands
}

const DEFAULT_VALUE = {
  videoRef: null,
  playerRef: null,
  ueCommands: null
} as unknown as IPixelStreamingContext

export const PixelStreamingContext = React.createContext<IPixelStreamingContext>(DEFAULT_VALUE)

export const PixelStreamingContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<HTMLDivElement>(null)

  const signalling = useSignalling()

  const onVideoInitialized = () => {
    console.log("video initialized", dataChannelRef)
    ueCommands.requestInitialSettings()
    ueCommands.requestQualityControl()
    // const playerWidth = playerRef.current?.clientWidth || 0
    // const playerHeight = playerRef.current?.clientHeight || 0

    ueEmitter.addListener("InitialSettings", (settings: unknown) => {
      console.log("INITIAL SETTINGS", { settings })
    })
  }

  const { onTrack } = useUE4Video({ videoRef, onVideoInitialized })

  const { onDataChannelMessage, emitter: ueEmitter } = useUEEvents()

  const { isDataChannelOpen, dataChannelRef } = useWebRTC({ ...signalling, onTrack, onDataChannelMessage })

  const ueCommands = useUECommands({ dataChannelRef, playerRef, videoRef })

  useUEMouseEvents({ playerRef, ueCommands })

  useEffect(() => {
    console.log("---------- data channel ready state changed", isDataChannelOpen)
  }, [isDataChannelOpen])

  const value = {
    videoRef,
    playerRef,
    ueCommands
  }

  return <PixelStreamingContext.Provider value={value}>{props.children}</PixelStreamingContext.Provider>
}
