import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"
import { Button } from "./Button"
import { IconType } from "./Icon"
import { Text } from "./Text"

const CardContainer = styled.div<{ pointer?: boolean }>`
  background-color: white;
  height: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: rgba(28, 103, 124, 1);
  font-weight: 600;

  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`

const CardHeader = styled.div`
  padding: 6px 10px;
`

const CardFooter = styled.div`
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CardContent = styled.div<{ backgroundUrl: string }>`
  background-color: rgba(196, 196, 196, 1);
  flex-grow: 1;
  background-size: cover;

  ${(props) => css`
    background-image: url("${props.backgroundUrl}");
  `}
`

export type CardProps = {
  imageUrl: string
  onClick?: () => void
}

export const Card = (props: CardProps): JSX.Element => {
  const { imageUrl } = props

  const [selected, setSelected] = useState<boolean>(false)

  const toggleSelected = useCallback(() => {
    setSelected(!selected)
  }, [selected])

  return (
    <CardContainer pointer={!!props.onClick} onClick={props.onClick}>
      <CardHeader>Phase 1</CardHeader>
      <CardContent backgroundUrl={imageUrl}></CardContent>
      <CardFooter>
        <Text>Espace / Type</Text>
        <Button
          icon={IconType.heart}
          intent="primary"
          selected={selected}
          selectedColor="#FF3B30"
          onClick={toggleSelected}
        />
      </CardFooter>
    </CardContainer>
  )
}
