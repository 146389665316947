import React, { PropsWithChildren, useCallback, useState } from "react"
import { noop } from "./index"

export type SalesPerson = {
  id: string
  name: string
}

export const SALES_PERSONS: SalesPerson[] = [
  { id: "sales-0", name: "John Doe" },
  { id: "sales-1", name: "Jane Dane" },
  { id: "sales-2", name: "Joe Dohn" },
  { id: "sales-3", name: "Vince Labrosse" }
]

export type LeadInfo = {
  email: string
  phone: string
}

export type ShowroomSession = {
  lead_info: LeadInfo
  salesperson: SalesPerson
  note: string
}

export type NewSessionParams = { lead_info: LeadInfo; salesperson: SalesPerson; note: string }
export type NewSessionFunc = (params: NewSessionParams) => void

export interface IShowroomSessionContext {
  salespersons: SalesPerson[]
  currentSession: ShowroomSession | null
  newSession: NewSessionFunc
}

const DEFAULT_VALUE: IShowroomSessionContext = {
  salespersons: SALES_PERSONS,
  currentSession: null,
  newSession: noop
}

export const ShowroomSessionContext = React.createContext<IShowroomSessionContext>(DEFAULT_VALUE)

export const ShowroomSessionContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const [currentSession, setCurrentSession] = useState<ShowroomSession | null>(null)

  const newSession = useCallback<NewSessionFunc>((params) => {
    setCurrentSession({
      lead_info: params.lead_info,
      salesperson: params.salesperson,
      note: params.note
    })
  }, [])

  const value = {
    salespersons: SALES_PERSONS,
    currentSession,
    newSession
  }

  return <ShowroomSessionContext.Provider value={value}>{props.children}</ShowroomSessionContext.Provider>
}
