import React from "react"
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import ShowroomPage from "./pages/ShowroomPage"

const App = (): JSX.Element => {
  return (
    <div className="App">
      <Router>
        <Route path="/showroom" component={ShowroomPage} />
        <Route render={() => <Redirect to="/showroom" />} />
      </Router>
    </div>
  )
}

export default App
