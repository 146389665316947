import React from "react"

import Showroom from "../components/Showroom"
import { WindowManager } from "../WindowManager/WindowManager"

import { RENDER_MAP, ShowroomWindowContext, ShowroomWindows } from "../contexts/ShowroomWindowContext"
import { ShowroomLevelContextProvider } from "../contexts/ShowroomLevelContext"
import { ShowroomSessionContextProvider } from "../contexts/ShowroomSessionContext"
import { PixelStreamingContextProvider } from "../contexts/PixelStreamingContext"

const ShowroomPage = (): JSX.Element => {
  return (
    <PixelStreamingContextProvider>
      <ShowroomSessionContextProvider>
        <ShowroomLevelContextProvider>
          <WindowManager<ShowroomWindows> context={ShowroomWindowContext} renderWindows={RENDER_MAP}>
            <Showroom />
          </WindowManager>
        </ShowroomLevelContextProvider>
      </ShowroomSessionContextProvider>
    </PixelStreamingContextProvider>
  )
}

export default ShowroomPage
