import React from "react"
import styled from "styled-components"
import { Icon, IconType } from "../Icon"

const Container = styled.div``

const UNITS = [
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  },
  {
    id: "1",
    name: "401A",
    phase: "1",
    floor: "4",
    bedrooms: "2",
    sqfeet: "1850",
    price: "850"
  }
]
type Unit = typeof UNITS[0]

const Table = styled.table`
  width: 100%;
  border: none;
  border-spacing: 0px 20px;
  padding: 0px 12px;
`
const HeaderCell = styled.th`
  font-size: 16px;
  font-weight: 300;
  color: #0d4657;
`

const StyledUnitRow = styled.tr`
  border-radius: 8px;
  overflow: hidden;
`
const UnitCell = styled.td`
  text-align: center;
  padding: 8px;
  background-color: rgba(6, 74, 95, 1);
  color: white;

  border-right: 1px solid rgba(0, 0, 0, 0.3);

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: none;
  }
`
type UnitRowProps = {
  unit: Unit
}
const UnitRow = (props: UnitRowProps): JSX.Element => {
  const { name, phase, floor, bedrooms, sqfeet, price } = props.unit
  return (
    <StyledUnitRow>
      <UnitCell>{name}</UnitCell>
      <UnitCell>{phase}</UnitCell>
      <UnitCell>{floor}</UnitCell>
      <UnitCell>{bedrooms}</UnitCell>
      <UnitCell>{sqfeet}</UnitCell>
      <UnitCell>{price} 000</UnitCell>
      <UnitCell>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Icon type={IconType.heart} size={24} color="white" />
        </div>
      </UnitCell>
    </StyledUnitRow>
  )
}

const UnitsList = (): JSX.Element => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <HeaderCell>Unité</HeaderCell>
            <HeaderCell>Phase</HeaderCell>
            <HeaderCell>Etage</HeaderCell>
            <HeaderCell>Chambres</HeaderCell>
            <HeaderCell>Surface</HeaderCell>
            <HeaderCell>Prix</HeaderCell>
            <HeaderCell>Favoris</HeaderCell>
          </tr>
        </thead>
        <tbody>
          {UNITS.map((unit) => {
            return <UnitRow key={unit.id} unit={unit} />
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default UnitsList
