import React, { PropsWithChildren, useContext, useMemo, useState } from "react"
import { UnitInfo } from "../types"
import { noop } from "./index"
import { PixelStreamingContext } from "./PixelStreamingContext"

export type ShowroomLevelID = "exterior_0" | "interior_0" | "interior_1" | "interior_2"
export type ShowroomLevelKind = "exterior" | "interior"

export const ALL_LEVELS: Set<ShowroomLevelKind> = new Set(["exterior", "interior"])
export const EXTERIOR_ONLY: Set<ShowroomLevelKind> = new Set(["exterior"])
export const INTERIOR_ONLY: Set<ShowroomLevelKind> = new Set(["interior"])

export interface IShowroomLevel {
  id: ShowroomLevelID
  kind: ShowroomLevelKind
}

export interface ExteriorShowroomLevel extends IShowroomLevel {
  id: "exterior_0"
  kind: "exterior"
}

export interface InteriorShowroomLevel extends IShowroomLevel {
  id: "interior_0" | "interior_1" | "interior_2"
  kind: "interior"
  unit: UnitInfo
}

export type ShowroomLevel = ExteriorShowroomLevel | InteriorShowroomLevel

export interface IShowroomLevelContext {
  levels: ShowroomLevel[]
  selectedLevel?: ShowroomLevel
  changeLevel: (id: ShowroomLevelID) => void
}

const LEVELS: ShowroomLevel[] = [
  { id: "exterior_0", kind: "exterior" },
  {
    id: "interior_0",
    kind: "interior",
    unit: {
      id: "1110c",
      level_id: "interior_0",
      name: "1110 C",
      floor: 11,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  },
  {
    id: "interior_1",
    kind: "interior",
    unit: {
      id: "1111a",
      level_id: "interior_0",
      name: "1111A",
      floor: 11,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 2,
      bathrooms: 1,
      orientation: "North"
    }
  },
  {
    id: "interior_2",
    kind: "interior",
    unit: {
      id: "1112b",
      level_id: "interior_0",
      name: "1112B",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 1,
      bathrooms: 1,
      orientation: "North"
    }
  }
]

const DEFAULT_VALUE: IShowroomLevelContext = {
  levels: LEVELS,
  selectedLevel: LEVELS[0],
  changeLevel: noop
}

export const ShowroomLevelContext = React.createContext(DEFAULT_VALUE)

export const ShowroomLevelContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)
  const [selectedLevelId, setSelectedLevelId] = useState<ShowroomLevelID>(LEVELS[0].id)

  const selectedLevel = useMemo(() => {
    return LEVELS.find((l) => l.id === selectedLevelId)
  }, [selectedLevelId])

  const changeLevel = (id: ShowroomLevelID) => {
    setSelectedLevelId(id)
    const level = LEVELS.find((l) => l.id === id)
    if (!level) return

    pixelCtx.ueCommands.emitUIInteraction({
      type: "change_level",
      ...level
    })
  }

  const value = {
    levels: LEVELS,
    selectedLevel,
    changeLevel
  }

  return <ShowroomLevelContext.Provider value={value}>{props.children}</ShowroomLevelContext.Provider>
}
