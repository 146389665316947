import React, { HTMLProps } from "react"
import styled from "styled-components"
import { InputLabel } from "./InputLabel"

const InputContainer = styled.div`
  padding: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
`

type InputProps = HTMLProps<HTMLInputElement> & {
  label: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <div>
      <InputLabel>{props.label}</InputLabel>
      <InputContainer>
        <input {...props} ref={ref}></input>
      </InputContainer>
    </div>
  )
})

Input.displayName = "Input"
