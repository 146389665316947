import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { Dock } from "../Dock"
import { ShowroomLevelContext } from "../../contexts/ShowroomLevelContext"
import { useDockItems } from "./useDockItems"
import { ShowroomSessionContext } from "../../contexts/ShowroomSessionContext"
import { NewSessionPage } from "../../pages/NewSessionPage"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { ShowroomWindowContext, ShowroomWindows } from "../../contexts/ShowroomWindowContext"

const Viewport = styled.div`
  background-size: cover;
  background-color: #000022;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 20px);
  overflow: hidden;
`

const VideoContainer = styled.video<{ imageURL: string }>`
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;

  ${(props) => `background-image: url("${props.imageURL}")`};
`

const Showroom = (): JSX.Element => {
  const windowCtx = useContext(ShowroomWindowContext)
  const pixelContext = useContext(PixelStreamingContext)
  const levelContext = useContext(ShowroomLevelContext)
  const sessionContext = useContext(ShowroomSessionContext)
  const dockItems = useDockItems()

  useEffect(() => {
    const level = levelContext.selectedLevel
    if (level && level.kind === "interior") {
      windowCtx.addWindow({
        family: ShowroomWindows.Teleportation,
        title: "Teleportation",
        sizing: { width: 400 },
        position: { top: 500 }
      })
    }
  }, [levelContext.selectedLevel])

  const imageURL =
    levelContext.selectedLevel?.kind === "exterior"
      ? "https://archvyz.com/wp-content/uploads/2021/10/20210929_170141000_iOS-scaled-e1634067246716-1700x1015.jpg"
      : "https://archvyz.com/wp-content/uploads/2021/10/UNIT_B_CAM_04-1200x1200.jpg"

  if (!sessionContext.currentSession) {
    return <NewSessionPage />
  } else {
    return (
      <Viewport ref={pixelContext.playerRef}>
        <Dock items={dockItems} />
        <VideoContainer
          id="uevideo"
          ref={pixelContext.videoRef}
          playsInline={true}
          autoPlay={true}
          muted={true}
          imageURL={imageURL}
        />
      </Viewport>
    )
  }
}

export default Showroom
