import React, { useContext } from "react"
import styled from "styled-components"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"

const AMENITIES = [
  { id: "urban_chalet", title: "Chalet Urbain" },
  { id: "coworking", title: "Lobby - Coworking" },
  { id: "garden", title: "Potager" },
  { id: "pool", title: "Piscine" },
  { id: "gym", title: "Salle d'entrainement" },
  { id: "terrasse", title: "Terrasse panoramique" }
]

const Container = styled.div`
  background-color: rgba(6, 74, 95, 1);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const AmenityItem = styled.div`
  background-color: #f4eeea;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  color: #0d4657;
  font-weight: 700;
  cursor: pointer;
`

const Amenities = (): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)

  const handleClick = (id: string) => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "amenity_focus",
      amenity_id: id
    })
  }

  return (
    <Container>
      {AMENITIES.map(({ id, title }) => {
        return (
          <AmenityItem key={id} onClick={() => handleClick(id)}>
            {title}
          </AmenityItem>
        )
      })}
    </Container>
  )
}

export default Amenities
