import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { FilterField, FiltersBar, Toggle, FilterFieldSet } from "../FiltersBar"
import { SegmentControl } from "../SegmentControl"
import { useSwitchGroup } from "../../hooks/useSwitchGroup"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { ALL_POINTS_OF_INTEREST, PointOfInterest, PointOfInterestCategory, POI_CATEGORY_METADATA } from "../../data/pois"
import { last } from "lodash"

const AroundContainer = styled.div``

const FILTER_SWITCHES: { id: PointOfInterestCategory; title: string }[] = [
  { id: "sports", title: "Sports" },
  { id: "services", title: "Services" },
  { id: "activities", title: "Activités" },
  { id: "shops_banks", title: "Epiceries & Banques" },
  { id: "cafes_restaurants", title: "Restaurants" }
]

const PROXIMITY_OPTIONS = [
  { id: "5", time: 5, title: "5 minutes" },
  { id: "10", time: 10, title: "10 minutes" },
  { id: "15", time: 15, title: "15 minutes" },
  { id: "25", time: 25, title: "25 minutes" }
]

type POI_Section = {
  title: string
  category: PointOfInterestCategory
  color: string
  items: PointOfInterest[]
}

const usePOISections = (pois: PointOfInterest[]): POI_Section[] => {
  const map = pois.reduce<Map<PointOfInterestCategory, PointOfInterest[]>>((map, poi) => {
    const existingArray = map.get(poi.category)
    if (existingArray) {
      map.set(poi.category, [...existingArray, poi])
    } else {
      map.set(poi.category, [poi])
    }

    return map
  }, new Map<PointOfInterestCategory, PointOfInterest[]>())

  const sections = Array.from(map.entries()).map(([category, items]) => {
    const { title, color } = POI_CATEGORY_METADATA[category]
    return {
      title,
      color,
      category,
      items
    }
  })

  return sections
}

const ContentContainer = styled.div`
  padding-bottom: 16px;
`
const SectionContainer = styled.div`
  padding: 8px 20px;
`
const SectionHeader = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #1c677c;
`
const ItemsList = styled.div`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`
const Item = styled.div<{ backgroundColor: string }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  background-color: ${(props) => props.backgroundColor};
  margin-top: 0px;
  color: white;
  font-weight: 600;
`

export const Around = (): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)
  const { allChecked, switchesValues, onSwitchChange } = useSwitchGroup<PointOfInterestCategory>({
    keys: FILTER_SWITCHES.map((s) => s.id)
  })
  const [proximity, setProximity] = useState(last(PROXIMITY_OPTIONS)?.id || "1000")

  const onProximityChange = (ids: string[]) => {
    setProximity(ids[0])
  }

  const handleClick = (id: string) => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "poi_item_focus",
      poi_item_id: id
    })
  }

  useEffect(() => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "around_filters_changed",
      filters: {
        switches: {
          all: allChecked,
          ...Object.fromEntries(switchesValues)
        },
        proximity
      }
    })
  }, [switchesValues, allChecked, proximity])

  const switchesFilteredPOIs = useMemo(() => {
    if (allChecked) return ALL_POINTS_OF_INTEREST
    return ALL_POINTS_OF_INTEREST.filter((poi) => {
      return !!switchesValues.get(poi.category)
    })
  }, [allChecked, switchesValues])

  const proximityFilteredPOIs = useMemo(() => {
    return switchesFilteredPOIs.filter((poi) => {
      return poi.time <= parseFloat(proximity)
    })
  }, [proximity, switchesFilteredPOIs])

  const pois_sections = usePOISections(proximityFilteredPOIs)

  return (
    <AroundContainer>
      <FiltersBar>
        <FilterFieldSet grid="1fr 1fr 1fr">
          <FilterField title="Tout">
            <Toggle checked={allChecked} onChange={onSwitchChange} id="all" />
          </FilterField>
          {FILTER_SWITCHES.map(({ id, title }) => {
            return (
              <FilterField key={id} title={title}>
                <Toggle checked={switchesValues.get(id) || false} onChange={onSwitchChange} id={id} />
              </FilterField>
            )
          })}
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Proximité">
            <SegmentControl items={PROXIMITY_OPTIONS} onChange={onProximityChange} defaultSelected={[proximity]} />
          </FilterField>
        </FilterFieldSet>
      </FiltersBar>
      <ContentContainer>
        {pois_sections.map((section) => {
          return (
            <SectionContainer key={section.title}>
              <SectionHeader>{section.title}</SectionHeader>
              <ItemsList>
                {section.items.map((item) => {
                  return (
                    <Item
                      key={item.name}
                      backgroundColor={section.color}
                      onClick={() => handleClick(`${item.category}_${item.poi_index}`)}
                    >
                      <span>{item.poi_index}</span>
                      <span>{item.name}</span>
                      <span>{`${item.time} min`}</span>
                    </Item>
                  )
                })}
              </ItemsList>
            </SectionContainer>
          )
        })}
        {!pois_sections.length && (
          <SectionContainer>
            <SectionHeader>NO RESULTS</SectionHeader>
          </SectionContainer>
        )}
      </ContentContainer>
    </AroundContainer>
  )
}

export default Around
