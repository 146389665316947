import React from "react"
import styled from "styled-components"
import { UnitInfo } from "../../types"
import { UnitCard } from "../UnitCard"

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TEMPLATE_UNITS: UnitInfo[] = [
  {
    id: "1110c",
    level_id: "interior_0",
    name: "1110 C",
    floor: 12,
    sqft: 1280,
    phase: 1,
    price: 1500000,
    bedrooms: 3,
    bathrooms: 2,
    orientation: "North"
  },
  {
    id: "1111c",
    level_id: "interior_1",
    name: "1111 C",
    floor: 12,
    sqft: 1280,
    phase: 1,
    price: 1500000,
    bedrooms: 3,
    bathrooms: 2,
    orientation: "North"
  },
  {
    id: "1112c",
    level_id: "interior_2",
    name: "1112 C",
    floor: 12,
    sqft: 1280,
    phase: 1,
    price: 1500000,
    bedrooms: 3,
    bathrooms: 2,
    orientation: "North"
  }
]

const TemplateUnits = (): JSX.Element => {
  return (
    <Container>
      {TEMPLATE_UNITS.map((unit) => {
        return (
          <UnitCard key={unit.id} unit={unit} collapsible actions={new Set(["blueprint", "visit", "favorite_toggle"])} />
        )
      })}
    </Container>
  )
}

export default TemplateUnits
