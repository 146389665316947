import React, { PropsWithChildren } from "react"
import styled from "styled-components"

const DialogContainer = styled.div`
  min-width: 100px;
  color: white;
  background-color: rgba(13, 71, 87, 0.65);
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  max-width: 420px;
`

const DialogHeader = styled.div`
  padding: 30px 30px 10px 30px;
`

const DialogHeaderTitle = styled.h3`
  color: rgba(255, 255, 255, 0.9);
`
const DialogHeaderMessage = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
`

export type DialogAction = {
  title: string
  onClick: () => void
}

export type DialogBoxProps = {
  title: string
  message: string
  actions?: DialogAction[]
}

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
`

export const Action = styled.button`
  cursor: pointer;
  font-family: inherit;
  background-color: transparent;
  outline: none;
  flex-grow: 1;
  color: white;
  padding: 12px;
  font-size: 20px;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

export const DialogBox = (props: PropsWithChildren<DialogBoxProps>): JSX.Element => {
  return (
    <DialogContainer>
      <DialogHeader>
        <DialogHeaderTitle>{props.title}</DialogHeaderTitle>
        <DialogHeaderMessage>{props.message}</DialogHeaderMessage>
      </DialogHeader>
      {props.children}
      {props.actions && props.actions.length && (
        <ActionsContainer>
          {props.actions.map((action) => {
            return (
              <Action key={action.title} onClick={action.onClick}>
                {action.title}
              </Action>
            )
          })}
        </ActionsContainer>
      )}
    </DialogContainer>
  )
}
