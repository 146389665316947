import React, { useContext, useState } from "react"
import styled, { css } from "styled-components"
import { ShowroomLevelContext } from "../contexts/ShowroomLevelContext"
import { UnitInfo } from "../types"
import { Button } from "./Button"
import { Icon, IconType } from "./Icon"

const CardContainer = styled.div`
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
`

const CardHeader = styled.div`
  background-color: #0d4657;
  cursor: pointer;
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
`

const HeaderColumn = styled.div<{ flexGrow?: number }>`
  ${(props) =>
    props.flexGrow &&
    css`
      flex-grow: ${props.flexGrow};
    `}
`

const CardContent = styled.div`
  background-color: #fff6f1;
`

const UnitAttributesContainer = styled.div`
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const UnitAttributeLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1c677c;
`

const UnitPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding: 0px 20px;
  color: #1c677c;
`

const FavoriteActions = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
`

export type UnitAttributeProps = {
  icon: IconType
  text: string
}

const UnitAttribute = (props: UnitAttributeProps): JSX.Element => {
  return (
    <UnitAttributeLine>
      <Icon type={props.icon} color="#FF8137" size={20} />
      <span style={{ marginLeft: "16px" }}>{props.text}</span>
    </UnitAttributeLine>
  )
}

export type UnitAttributesProps = {
  unit: UnitInfo
}

const UnitAttributes = (props: UnitAttributesProps): JSX.Element => {
  const { bedrooms, bathrooms, orientation } = props.unit
  return (
    <UnitAttributesContainer>
      <UnitAttribute icon={IconType.bed} text={`${bedrooms} Chambres`} />
      <UnitAttribute icon={IconType.shower} text={`${bathrooms} salles de bain`} />
      <UnitAttribute icon={IconType.compass} text={`${orientation}`} />
    </UnitAttributesContainer>
  )
}

const useFloorDescription = (floor: number) => {
  if (floor === 1) return "1er"
  return `${floor}eme`
}
const useUnitDescription = (unit: UnitInfo) => {
  const { floor, sqft, phase } = unit
  const floorDesc = useFloorDescription(floor)
  return `${floorDesc} Étage / ${sqft} pi2 / phase ${phase}`
}
const usePriceDescription = (price: number) => {
  return `$${price}`
}

export type UnitCardAction = "blueprint" | "visit" | "favorite_toggle" | "favorite_delete"
export type UnitCardProps = {
  unit: UnitInfo
  actions: Set<UnitCardAction>
  collapsible?: boolean
}

export const UnitCard = (props: UnitCardProps): JSX.Element => {
  const levelCtx = useContext(ShowroomLevelContext)
  const [isOpen, setIsOpen] = useState(!props.collapsible)

  const { name, price } = props.unit
  const description = useUnitDescription(props.unit)
  const pricedesc = usePriceDescription(price)

  const handleVisitClick = () => {
    const currentLevel = levelCtx.selectedLevel
    if (!currentLevel) return
    if (currentLevel.id === props.unit.level_id) {
      return
    }

    levelCtx.changeLevel(props.unit.level_id)
  }

  return (
    <CardContainer>
      <CardHeader onClick={() => setIsOpen(!isOpen)}>
        <HeaderColumn flexGrow={1}>
          <h1>{name}</h1>
          <h4 style={{ fontWeight: 500 }}>{description}</h4>
        </HeaderColumn>
        {props.collapsible && (
          <HeaderColumn>
            <Button
              intent="util"
              icon={IconType.arrow_right_circle}
              iconSize={24}
              iconTransform={isOpen ? "rotate(90)" : undefined}
            >
              Open/Close
            </Button>
          </HeaderColumn>
        )}
      </CardHeader>
      {isOpen && (
        <CardContent>
          <UnitAttributes unit={props.unit} />
          <UnitPrice>{pricedesc}</UnitPrice>
          <FavoriteActions>
            {props.actions.has("blueprint") && (
              <Button intent="primary" label="Plan" style={{ flexGrow: 1 }} padding="12px" />
            )}
            {props.actions.has("visit") && (
              <Button intent="primary" label="Visite Virtuelle" onClick={() => handleVisitClick()} />
            )}
            {props.actions.has("favorite_delete") && <Button intent="primary" icon={IconType.trash} padding="12px" />}
            {props.actions.has("favorite_toggle") && (
              <Button intent="primary" icon={IconType.heart} padding="12px" selected={true} />
            )}
          </FavoriteActions>
        </CardContent>
      )}
    </CardContainer>
  )
}
