import React, { useContext } from "react"
import styled from "styled-components"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { useSwitchGroup } from "../../hooks/useSwitchGroup"
import { Card } from "../Card"
import { FilterField, FilterFieldSet, FiltersBar, Toggle } from "../FiltersBar"

const GalleryContainer = styled.div`
  /* background-color: rgba(255, 246, 241, 0.85); */
  height: 100%;
`

const CardListContainer = styled.div`
  padding: 16px 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`

const GALLERY_OBJECTS = [
  {
    id: "gallery_1",
    url: "https://unsplash.com/photos/tleCJiDOri0/download?force=true&w=2400"
  },
  {
    id: "gallery_2",
    url: "https://unsplash.com/photos/mx4mSkK9zeo/download?force=true&w=2400"
  },
  {
    id: "gallery_3",
    url: "https://unsplash.com/photos/JdcJn85xD2k/download?force=true&w=1920"
  },
  {
    id: "gallery_4",
    url: "https://unsplash.com/photos/nhWgZNV85LQ/download?force=true&w=2400"
  },
  {
    id: "gallery_5",
    url: "https://unsplash.com/photos/UXFJ-6Zj27M/download?force=true&w=2400"
  },
  {
    id: "gallery_6",
    url: "https://unsplash.com/photos/4HG3Ca3EzWw/download?force=true&w=2400"
  },
  {
    id: "gallery_7",
    url: "https://unsplash.com/photos/9M66C_w_ToM/download?force=true&w=2400"
  }
]

const CardList = (): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)

  const handleClick = (id: string) => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "open_gallery_item",
      item_id: id
    })
  }

  return (
    <CardListContainer>
      {GALLERY_OBJECTS.map((o) => {
        return <Card key={o.id} imageUrl={o.url} onClick={() => handleClick(o.id)} />
      })}
    </CardListContainer>
  )
}

const Gallery = (): JSX.Element => {
  const { allChecked, switchesValues, onSwitchChange } = useSwitchGroup({ keys: ["3d", "animations"] })

  return (
    <GalleryContainer>
      <FiltersBar>
        <FilterFieldSet>
          <FilterField title="Tout">
            <Toggle checked={allChecked} onChange={onSwitchChange} id="all" />
          </FilterField>
          <FilterField title="Rendus 3D">
            <Toggle checked={switchesValues.get("3d") || false} onChange={onSwitchChange} id="3d" />
          </FilterField>
          <FilterField title="Animations">
            <Toggle checked={switchesValues.get("animations") || false} onChange={onSwitchChange} id="animations" />
          </FilterField>
        </FilterFieldSet>
      </FiltersBar>
      <CardList />
    </GalleryContainer>
  )
}

export default Gallery
