import React, { PropsWithChildren, useContext, useState } from "react"
import styled from "styled-components"
import { DialogBox } from "../components/Dialog"
import { Input } from "../components/Input"
import { Select } from "../components/Select"
import { TextArea } from "../components/TextArea"
import { SalesPerson, SALES_PERSONS, ShowroomSessionContext } from "../contexts/ShowroomSessionContext"

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://lesloges-public-s3-assets-prod-1.s3.amazonaws.com/media/images/availability-img-home.original.png");
  background-size: cover;
  overflow: hidden;
`

const PortalContainer = styled.dialog`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`
const Portal = (props: PropsWithChildren<unknown>): JSX.Element => {
  return <PortalContainer>{props.children}</PortalContainer>
}

export const Form = styled.form`
  padding: 20px;
`

export const FieldSet = styled.fieldset`
  border: none;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  font-size: 14px;
`

export const NewSessionPage = (): JSX.Element => {
  const sessionContext = useContext(ShowroomSessionContext)

  const [email, setEmail] = useState("")
  const [salesperson, setSalesPerson] = useState<SalesPerson | null>(null)
  const [phone, setPhone] = useState("")
  const [note, setNote] = useState("")

  const startSession = () => {
    // if (!salesperson) return
    sessionContext.newSession({
      lead_info: {
        email,
        phone
      },
      salesperson: SALES_PERSONS[0],
      note
    })
  }

  return (
    <Wrapper>
      <Portal>
        <DialogBox
          title="VIVENDA"
          message="A message should be a short, complete sentence."
          actions={[
            {
              title: "Commencer",
              onClick: () => startSession()
            }
          ]}
        >
          <Form>
            <FieldSet>
              <Input
                label="Email"
                name="customer_email"
                type="email"
                placeholder="example@example.com"
                value={email}
                onChange={(ev) => setEmail(ev.currentTarget.value)}
              />
              <Select
                label="Your salesperson"
                name="sales"
                isMulti={false}
                value={salesperson}
                placeholder="Select a salesperson from the list..."
                options={sessionContext.salespersons}
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.id}
                onChange={(newValue) => {
                  if (!newValue) return
                  setSalesPerson(newValue)
                }}
              />
              <Input
                label="Phone"
                name="phone"
                type="phone"
                placeholder="123 456 7890"
                value={phone}
                onChange={(ev) => setPhone(ev.currentTarget.value)}
              />
              <TextArea label="Note" name="note" value={note} onChange={(ev) => setNote(ev.currentTarget.value)} />
            </FieldSet>
          </Form>
        </DialogBox>
      </Portal>
    </Wrapper>
  )
}
