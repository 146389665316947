import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

const SegmentControlContainer = styled.div`
  display: flex;
  justify-content: stretch;
  /* padding: 0px 20px; */
  gap: 4px;
  flex-grow: 0.67;
`
const SegmentItem = styled.div<{ selected?: boolean }>`
  padding: 8px 12px;
  flex-grow: 1;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;

  ${(props) =>
    props.selected
      ? css`
          background-color: #ff8137;
          color: white;
        `
      : css`
          background-color: #e5e5e5;
          color: black;
        `}
`

export type SegmentControlItem<T extends string> = {
  id: T
  title: string
}
export type SegmentControlProps<ItemType extends string> = {
  items: SegmentControlItem<ItemType>[]
  multi?: boolean
  defaultSelected: ItemType[]
  onChange: (selectedValues: ItemType[]) => void
}
export const SegmentControl = <ItemType extends string>(props: SegmentControlProps<ItemType>): JSX.Element => {
  const [selectedValues, setSelectedValues] = useState(new Set<ItemType>([...props.defaultSelected]))

  const onClick = (id: ItemType) => {
    const selected = selectedValues.has(id)

    if (selected)
      setSelectedValues((prev) => {
        const newSet = new Set([...prev])
        if (newSet.size === 1) return newSet
        newSet.delete(id)
        return newSet
      })
    else {
      if (props.multi) {
        setSelectedValues((prev) => {
          const newSet = new Set([...prev])
          newSet.add(id)
          return newSet
        })
      } else {
        setSelectedValues(new Set([id]))
      }
    }
  }

  useEffect(() => {
    props.onChange(Array.from(selectedValues))
  }, [selectedValues])

  return (
    <SegmentControlContainer>
      {props.items.map((item) => {
        return (
          <SegmentItem key={item.id} selected={selectedValues.has(item.id)} onClick={() => onClick(item.id)}>
            {item.title}
          </SegmentItem>
        )
      })}
    </SegmentControlContainer>
  )
}
