import React, { HTMLProps } from "react"
import styled from "styled-components"
import { InputLabel } from "./InputLabel"

const TextAreaContainer = styled.div`
  padding: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
`

type TextAreaProps = HTMLProps<HTMLTextAreaElement> & {
  label: string
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  return (
    <div>
      <InputLabel>{props.label}</InputLabel>
      <TextAreaContainer>
        <textarea {...props} ref={ref} style={{ resize: "none" }}></textarea>
      </TextAreaContainer>
    </div>
  )
})

TextArea.displayName = "TextArea"
