import React, { useContext, useState } from "react"
import { Slider, SliderProps } from "@mui/material"
import styled from "styled-components"
import { useSwitchGroup } from "../../hooks/useSwitchGroup"
import { FilterField, FilterFieldSet, FiltersBar, Toggle } from "../FiltersBar"
import { SegmentControl } from "../SegmentControl"
import { Icon, IconType } from "../Icon"
import { ShowroomWindowContext, ShowroomWindows } from "../../contexts/ShowroomWindowContext"

const SWITCH_KEY_APPARTMENTS = "appartments"
const SWITCH_KEY_COMMERCIAL = "commercial"

const Container = styled.div``
const ControlHandleContainer = styled.div`
  background-color: #f7f7fa;
  flex-grow: 0.6;
  text-align: center;
  color: black;
  padding: 6px 20px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  transition: all 0.3s;
`

const ActionsWrapper = styled.div`
  padding: 12px;
  background-color: rgba(6, 74, 95, 1);
`
const ActionsContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`
const ActionItemContainer = styled.div<{ foregroundColor: string }>`
  margin-top: 1px;
  background-color: #fff6f1;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 700;
  /* color: rgba(6, 74, 95, 1); */
  cursor: pointer;
  color: ${(props) => props.foregroundColor};
`
const COLOR_BY_INTENT = {
  primary: "#0D4657",
  destructive: "#FF3B30"
}
type ActionIntent = keyof typeof COLOR_BY_INTENT
type ActionItemProps = {
  title: string
  icon?: IconType
  intent: ActionIntent
  onClick: () => void
}
const ActionItem = (props: ActionItemProps): JSX.Element => {
  const color = COLOR_BY_INTENT[props.intent]
  return (
    <ActionItemContainer foregroundColor={color} onClick={props.onClick}>
      <span>{props.title}</span>
      {props.icon && <Icon type={props.icon} color={color} size={16} />}
    </ActionItemContainer>
  )
}

export type SliderHandleProps = {
  titleRenderer: (values: number | number[] | null) => string
}

const SliderHandle = (props: SliderHandleProps & SliderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <ControlHandleContainer onClick={toggleOpen}>
      <div style={{ color: "rgba(6, 74, 95, 1)" }}>{props.titleRenderer(props.value || null)}</div>
      {isOpen && <Slider {...props} />}
    </ControlHandleContainer>
  )
}

const MIN_FLOOR = 0
const MAX_FLOOR = 10
const MIN_SQFEET = 400
const MAX_SQFEET = 2000
const MIN_PRICE = 300
const MAX_PRICE = 1500

const MasterFilters = (): JSX.Element => {
  const windowCtx = useContext(ShowroomWindowContext)
  const { switchesValues, onSwitchChange } = useSwitchGroup({ keys: [SWITCH_KEY_APPARTMENTS, SWITCH_KEY_COMMERCIAL] })

  const [floorRange, setFloorRange] = useState<number | number[] | null>(null)
  const [nbRooms, setNbRooms] = useState<"all" | "1" | "2" | "3">("all")
  const [sqfeetRange, setSqfeetRange] = useState<number | number[] | null>(null)
  const [priceRange, setPriceRange] = useState<number | number[] | null>(null)
  const [orientation, setOrientation] = useState<("north" | "east" | "west" | "south")[]>(["north"])

  return (
    <Container>
      <FiltersBar hidesHeader>
        <FilterFieldSet>
          <FilterField title="Appartement">
            <Toggle
              checked={switchesValues.get(SWITCH_KEY_APPARTMENTS) || false}
              onChange={onSwitchChange}
              id={SWITCH_KEY_APPARTMENTS}
            />
          </FilterField>
          <FilterField title="Commercial">
            <Toggle
              checked={switchesValues.get(SWITCH_KEY_COMMERCIAL) || false}
              onChange={onSwitchChange}
              id={SWITCH_KEY_COMMERCIAL}
            />
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Etage" grid="2fr 5fr">
            <SliderHandle
              titleRenderer={(values) => {
                if (!values || values.constructor !== Array) {
                  return "Choisir son etage"
                }
                return `Entre le ${values[0]} et le ${values[1]}`
              }}
              value={floorRange || [MIN_FLOOR, MAX_FLOOR]}
              min={MIN_FLOOR}
              max={MAX_FLOOR}
              step={1}
              marks
              onChange={(ev, values) => setFloorRange(values)}
            ></SliderHandle>
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Chambres" grid="2fr 5fr">
            <SegmentControl
              defaultSelected={["all"]}
              onChange={(value) => setNbRooms(value[0])}
              items={[
                { id: "all", title: "Tout" },
                { id: "1", title: "1" },
                { id: "2", title: "2" },
                { id: "3", title: "3" }
              ]}
            />
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Superficie (pi2)" grid="2fr 5fr">
            <SliderHandle
              titleRenderer={(values) => {
                if (!values || values.constructor !== Array) {
                  return "Choisir la superficie"
                }
                return `Entre ${values[0]} et ${values[1]} pieds carré`
              }}
              value={sqfeetRange || [MIN_SQFEET, MAX_SQFEET]}
              min={MIN_SQFEET}
              max={MAX_SQFEET}
              step={10}
              marks
              onChange={(ev, values) => setSqfeetRange(values)}
            ></SliderHandle>
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Prix" grid="2fr 5fr">
            <SliderHandle
              titleRenderer={(values) => {
                if (!values || values.constructor !== Array) {
                  return "Choisir son prix"
                }
                return `Entre $${values[0]}k et $${values[1]}k`
              }}
              value={priceRange || [MIN_PRICE, MAX_PRICE]}
              min={MIN_PRICE}
              max={MAX_PRICE}
              step={5}
              marks
              onChange={(ev, values) => setPriceRange(values)}
            ></SliderHandle>
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Orientation" grid="2fr 5fr">
            <SegmentControl
              defaultSelected={["north"]}
              multi
              onChange={(value) => setOrientation(value)}
              items={[
                { id: "north", title: "Nord" },
                { id: "south", title: "Sud" },
                { id: "east", title: "Est" },
                { id: "west", title: "Ouest" }
              ]}
            />
          </FilterField>
        </FilterFieldSet>
      </FiltersBar>
      <ActionsWrapper>
        <ActionsContainer>
          <ActionItem
            intent="primary"
            title="Voir la liste des unites"
            icon={IconType.building}
            onClick={() =>
              windowCtx.addWindow({ title: "Liste des unités", family: ShowroomWindows.UnitsList, sizing: { height: 700 } })
            }
          />
          <ActionItem
            intent="destructive"
            title="Effacer les filtres"
            icon={IconType.trash}
            onClick={() => console.log("delete filters")}
          ></ActionItem>
        </ActionsContainer>
      </ActionsWrapper>
    </Container>
  )
}

export default MasterFilters
