import React from "react"
import styled from "styled-components"
import { ShowroomLevelKind } from "../../contexts/ShowroomLevelContext"
import { Icon, IconType } from "../Icon"

const BottomBar = styled.div`
  background: clear;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

const DockWrapper = styled.div`
  border-radius: 20px;
  background-color: rgba(9, 55, 73, 0.75);
  margin-bottom: 12px;
  z-index: 3000;
`

const DockContainer = styled.div`
  border-radius: 20px;
  padding: 18px 18px 28px 18px;

  backdrop-filter: blur(20px);
  display: flex;
`

const StyledDockItem = styled.div`
  border-radius: 12px;
  background-color: white;
  width: 78px;
  height: 78px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const StyledDockItemTitle = styled.div`
  position: absolute;
  bottom: -28px;
  text-align: center;
  width: 120%;
  font-size: 12px;
  color: white;
`

export type DockItem = {
  title: string
  icon: IconType
  levelKinds: Set<ShowroomLevelKind>
  onClick?: () => void
}

export type DockItemProps = {
  dockItem: DockItem
}

const DockItem = (props: DockItemProps): JSX.Element => {
  const { onClick, icon, title } = props.dockItem
  return (
    <StyledDockItem onClick={onClick}>
      <Icon type={icon} size={58} color="rgba(255, 129, 55, 1)" />
      <StyledDockItemTitle>{title}</StyledDockItemTitle>
    </StyledDockItem>
  )
}

export type DockProps = {
  items: DockItem[]
}

export const Dock = (props: DockProps): JSX.Element => {
  const { items } = props

  return (
    <BottomBar>
      <DockWrapper>
        <DockContainer>
          {items.map((item) => {
            return <DockItem key={item.title} dockItem={item} />
          })}
        </DockContainer>
      </DockWrapper>
    </BottomBar>
  )
}
