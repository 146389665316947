import React, { PropsWithChildren, useContext } from "react"
import { useDrag } from "react-dnd"
import styled, { css } from "styled-components"
import { WindowHeader } from "./WindowHeader"
import { IWindow, IWindowManagerContext } from "./WindowManagerContext"

export type WindowProps<Family extends string> = {
  window: IWindow<Family>
  context: React.Context<IWindowManagerContext<Family>>
}

export type WindowWrapperProps = {
  width: number
  height?: number
  top: number
  left: number
}

export const WindowWrapper = styled.div<WindowWrapperProps>`
  border-radius: 10px;
  position: absolute;
  z-index: 2000;
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${(props) => {
    return css`
      width: ${props.width}px;
      top: ${props.top}px;
      left: ${props.left}px;
    `
  }};

  ${(props) =>
    props.height &&
    css`
      max-height: ${props.height}px;
    `}
`

export const ContentWrapper = styled.div`
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(255, 246, 241, 0.9);
  overflow-y: scroll;
  height: 100%;
`

export const Window = <Family extends string>(props: PropsWithChildren<WindowProps<Family>>): JSX.Element => {
  const { window } = props
  const { position } = window

  const windowCtx = useContext(props.context)

  // const content = useMemo(() => {
  //   return
  // }, [window])

  const [{ isDragging }, drag, preview] = useDrag(() => {
    return {
      type: "window",
      item: window,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag()
      })
    }
  }, [window.id, window.position.left, window.position.top])

  const content = window.renderContent({ window })

  if (isDragging) {
    // hide source on drag
    return <div ref={drag} />
  }

  return (
    <WindowWrapper
      ref={preview}
      width={props.window.sizing?.width || 500}
      height={props.window.sizing?.height}
      id={window.id}
      top={position.top}
      left={position.left}
      role="window"
    >
      <WindowHeader ref={drag} window={window} closeWindow={windowCtx.closeWindow} />
      <ContentWrapper>{content}</ContentWrapper>
    </WindowWrapper>
  )
}
