import React, { PropsWithChildren } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
// import { TouchBackend } from "react-dnd-touch-backend"

import { IWindowManagerContext, RenderWindowMap, WindowManagerContextProvider } from "./WindowManagerContext"

export interface WindowManagerProps<WindowFamily extends string> {
  context: React.Context<IWindowManagerContext<WindowFamily>>
  renderWindows: RenderWindowMap<WindowFamily>
}

export const WindowManager = <Family extends string>(props: PropsWithChildren<WindowManagerProps<Family>>): JSX.Element => {
  return (
    <DndProvider backend={HTML5Backend}>
      <WindowManagerContextProvider renderWindows={props.renderWindows} context={props.context}>
        {props.children}
      </WindowManagerContextProvider>
    </DndProvider>
  )
}

export default WindowManager
