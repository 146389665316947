import { useCallback, useEffect, useRef } from "react"
import EventEmitter from "events"

export interface Signalling {
  onOfferCreated: (offer: RTCSessionDescriptionInit) => void
  onIceCandidate: (iceEvent: RTCPeerConnectionIceEvent) => void
  emitter: EventEmitter
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseSignallingProps {}

export const useSignalling = (): Signalling => {
  const wsRef = useRef<WebSocket | null>(null)
  const emitterRef = useRef(new EventEmitter())

  const initialize = () => {
    const ws = new WebSocket("ws://192.168.1.126:8080")
    ws.onopen = (ev) => {
      console.log("WS signalling connection open...", ev)
    }
    ws.onmessage = handleMessage
    wsRef.current = ws
  }

  const handleMessage = useCallback((ev: MessageEvent<string>) => {
    try {
      const data = JSON.parse(ev.data)
      // console.log("Signalling message from streamer", data.type)
      console.log("emitting", data)
      emitterRef.current.emit(data.type, data)
    } catch (e) {
      console.error("Failed to handle message", e)
    }
  }, [])

  const sendMessage = useCallback(
    (msg: RTCSessionDescriptionInit | RTCPeerConnectionIceEvent) => {
      wsRef.current?.send(JSON.stringify(msg))
    },
    [wsRef]
  )

  const onOfferCreated = useCallback(
    (offer: RTCSessionDescriptionInit) => {
      sendMessage(offer)
    },
    [sendMessage]
  )

  const onIceCandidate = useCallback(
    (iceEvent: RTCPeerConnectionIceEvent) => {
      sendMessage(iceEvent)
    },
    [sendMessage]
  )

  useEffect(() => {
    initialize()
  }, [])

  return {
    onOfferCreated,
    onIceCandidate,
    emitter: emitterRef.current
  }
}
