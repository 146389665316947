import React from "react"
import styled from "styled-components"
import { Button } from "../components/Button"
import { IconType } from "../components/Icon"
import { CloseWindowParams, IWindow } from "./WindowManagerContext"

export const WindowHeaderTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`

export const WindowHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 12px 8px 12px;
  color: white;
  display: flex;
  justify-content: space-between;
  background-color: rgba(6, 74, 95, 1);
  backdrop-filter: blur(8px);
  border-radius: 10px 10px 0px 0px;
  cursor: move;

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
`

export type WindowHeaderProps = {
  window: Pick<IWindow<never>, "id" | "title">
  closeWindow: (params: CloseWindowParams) => void
}

export const WindowHeader = React.forwardRef<HTMLDivElement, WindowHeaderProps>((props, ref) => {
  const { window } = props
  return (
    <>
      <WindowHeaderContainer ref={ref}>
        <WindowHeaderTitle>{window.title}</WindowHeaderTitle>
        <Button intent="util" icon={IconType.close} onClick={() => props.closeWindow(window)} />
      </WindowHeaderContainer>
    </>
  )
})

WindowHeader.displayName = "WindowHeader"
