import Amenities from "../components/Windows/Amenities"
import Around from "../components/Windows/Around"
import Favorites from "../components/Windows/Favorites"
import Gallery from "../components/Windows/Gallery"
import InteriorCustomization from "../components/Windows/InteriorCustomization"
import MasterFilters from "../components/Windows/MasterFilters"
import Teleportation from "../components/Windows/Teleportation"
import TemplateUnits from "../components/Windows/TemplateUnits"
import UnitsList from "../components/Windows/UnitsList"

import { createWindowManagerContext, RenderWindowMap } from "../WindowManager/WindowManagerContext"

export enum ShowroomWindows {
  Gallery = "gallery",
  Favorites = "favorites",
  Around = "around",
  MasterFilters = "filters",
  UnitsList = "units_list",
  Amenities = "amenities",
  TemplateUnits = "template_units",
  InteriorCustomization = "interior_customization",
  Teleportation = "teleportation"
}

export const RENDER_MAP: RenderWindowMap<ShowroomWindows> = {
  gallery: Gallery,
  favorites: Favorites,
  around: Around,
  filters: MasterFilters,
  units_list: UnitsList,
  amenities: Amenities,
  template_units: TemplateUnits,
  interior_customization: InteriorCustomization,
  teleportation: Teleportation
}

export const ShowroomWindowContext = createWindowManagerContext<ShowroomWindows>()
