import { RefObject, useEffect } from "react"
import { UECommands } from "./commands"

export type UseMouseEventsProps = {
  playerRef: RefObject<HTMLDivElement>
  ueCommands: UECommands
}

export const useUEMouseEvents = (props: UseMouseEventsProps): void => {
  const { playerRef, ueCommands } = props

  const registerEvents = () => {
    if (!playerRef.current) {
      console.log("Cannot register mouse events, playerRef is unavailable")
      return () => undefined
    }

    const mouseenter = () => ueCommands.emitMouseEnter()
    const mouseleave = () => ueCommands.emitMouseLeave()
    const mousedown = (ev: MouseEvent) => ueCommands.emitMouseDown(ev.button, ev.clientX, ev.clientY)
    const mouseup = (ev: MouseEvent) => ueCommands.emitMouseUp(ev.button, ev.clientX, ev.clientY)
    const mousemove = (ev: MouseEvent) => ueCommands.emitMouseMove(ev.offsetX, ev.offsetY, ev.movementX, ev.movementY)

    playerRef.current.addEventListener("mouseenter", mouseenter)
    playerRef.current.addEventListener("mouseleave", mouseleave)
    playerRef.current.addEventListener("mousedown", mousedown)
    playerRef.current.addEventListener("mouseup", mouseup)
    playerRef.current.addEventListener("mousemove", mousemove)

    return () => {
      playerRef.current?.removeEventListener("mouseenter", mouseenter)
      playerRef.current?.removeEventListener("mouseleave", mouseleave)
      playerRef.current?.removeEventListener("mousedown", mousedown)
      playerRef.current?.removeEventListener("mouseup", mouseup)
      playerRef.current?.removeEventListener("mousemove", mousemove)
    }
  }

  useEffect(() => {
    const unregisterEvents = registerEvents()
    return () => {
      unregisterEvents()
    }
  }, [])
}
