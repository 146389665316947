import React from "react"
import styled from "styled-components"
import { UnitInfo } from "../../types"

import { UnitCard } from "../UnitCard"

const FavoritesContainer = styled.div`
  /* background-color: rgba(255, 246, 241, 0.85); */
  height: 100%;
`

const CardListContainer = styled.div`
  padding: 16px 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`

export type FavoriteUnit = {
  type: "unit"
  unit: UnitInfo
}

export type Favorite = FavoriteUnit

const FAVORITE_OBJECTS: Favorite[] = [
  {
    type: "unit",
    unit: {
      id: "1110c",
      level_id: "interior_0",
      name: "1110 C",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  },
  {
    type: "unit",
    unit: {
      id: "1110c",
      level_id: "interior_1",
      name: "1110 C",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  },
  {
    type: "unit",
    unit: {
      id: "1110c",
      level_id: "interior_2",
      name: "1110 C",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  },
  {
    type: "unit",
    unit: {
      id: "1110c",
      level_id: "interior_0",
      name: "1110 C",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  },
  {
    type: "unit",
    unit: {
      id: "1110c",
      level_id: "interior_0",
      name: "1110 C",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  },
  {
    type: "unit",
    unit: {
      id: "1110c",
      level_id: "interior_0",
      name: "1110 C",
      floor: 12,
      sqft: 1280,
      phase: 1,
      price: 1500000,
      bedrooms: 3,
      bathrooms: 2,
      orientation: "North"
    }
  }
]

export type FavoriteCardProps = {
  favorite: {
    name: string
    description: string
  }
}

const CardList = (): JSX.Element => {
  return (
    <CardListContainer>
      {FAVORITE_OBJECTS.map((fave) => {
        const { unit } = fave
        return <UnitCard key={unit.id} collapsible={false} unit={unit} actions={new Set(["blueprint", "favorite_delete"])} />
      })}
    </CardListContainer>
  )
}

const Favorites = (): JSX.Element => {
  return (
    <FavoritesContainer>
      <CardList />
    </FavoritesContainer>
  )
}

export default Favorites
